import { IVoiceMessageListItem } from '@interfaces';
import { ResourceTable, ResourceTableProps } from '@components/table/ResourceTable';

import VoiceMessageTableColumns from './VoiceMessageTableColumns';
import VoiceMessageTableExpandedRow from './VoiceMessageTableExpandedRow';
import { useIdentity } from '@components/data/Identity.context';
import { useChannel, useEvent } from '@harelpls/use-pusher';
import { Payload } from '@components/ui/notifications/LiveNotifications';
import { useInvalidate } from '@refinedev/core';

export const VoiceMessageTable = (props: ResourceTableProps<IVoiceMessageListItem>) => {
  const { identity } = useIdentity();
  const invalidate = useInvalidate();

  const ownerChannel = useChannel(`private-activity.owner.${identity?.owner?.id ?? 0}`);
  useEvent(ownerChannel, 'activity', (data: Payload) => {
    if (data.verb === 'finished') {
      invalidate({ resource: 'voice_message', invalidates: ['all'] })
    }
  });

  return <ResourceTable<IVoiceMessageListItem>
    resource="voice_message"
    columns={VoiceMessageTableColumns}
    onRenderExpanded={VoiceMessageTableExpandedRow}
    excludeColumns={['id']}
    allowExports={false}
    {...props}
    tableProps={{
      ...props.tableProps,
      rowProps: (record) => ({
        'data-flagged': record.customer_marked_status === 'Flagged',
        'data-blocked': record.customer_marked_status === 'Blocked',
      })
    }}
  />;
}
