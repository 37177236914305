import React, { ReactNode } from 'react';
import Link from 'next/link';
import { useMenu } from '@refinedev/core';
import {
  Group,
  NavLink,
  Text,
  Avatar,
  Stack,
  Menu,
  useMantineTheme,
  Loader,
  Skeleton, Drawer, Divider, isLightColor
} from '@mantine/core';
import { IconLogout, IconChevronRight, IconChevronDown, IconSettings } from '@tabler/icons-react';
import { useIdentity } from '@components/data/Identity.context';
import { SidebarSwitchOwnerSection } from '@components/layout/SidebarSwitchOwnerSection';
import { useFeature } from 'flagged';
import { useClickOutside, useMediaQuery } from '@mantine/hooks';
import chroma from 'chroma-js';

import classes from './Sidebar.module.css';

interface Props {
  menu: ReactNode[];
  opened: boolean;
  setMenu: (opened: boolean) => void;
}

export function SidebarOverlayMenu({ menu, opened, setMenu }: Props) {
  const { selectedKey } = useMenu();
  const { identity, isOrgSwitching, logout } = useIdentity();
  const ref = useClickOutside(() => setMenu(false));
  const theme = useMantineTheme();
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`, false);

  const isAdmin = useFeature('admin');

  const logoBgColor = identity?.owner?.theme_color ?? '#FBBF24';
  const logoTextColor = chroma.contrast(logoBgColor, 'white') < 4.5 ? 'black' : 'white';

  const MenuItems = () => <>
    { menu }

    { isAdmin && <Divider /> }

    { !isAdmin && <>
      <SidebarSwitchOwnerSection setMenu={setMenu} />
    </>}

    <NavLink renderRoot={(props) => <Link href="/profile" {...props} />}
             leftSection={<IconSettings size={16} stroke={1.5} />}
             label="Account Settings"
             onClick={() => setMenu(false)}
             active={selectedKey === '/profile'}
    />

    <NavLink component="span"
             leftSection={<IconLogout size={16} stroke={1.5} />}
             label="Logout"
             onClick={() => logout()}
    />
  </>;

  return <>
    <Menu
      withArrow
      trigger={ isMobile ? 'click' : 'hover' }
      shadow="sm"
      width={275}
      offset={{
        mainAxis: 25,
      }}
      opened={!isMobile && opened}
      position="right-start"
    >
      <Menu.Target>
        <Group wrap="nowrap" align="middle" style={{ overflow: 'hidden' }} className={classes.sidebarDropdown} onClick={() => setMenu(!opened)}>
          <Avatar radius="sm" styles={{ placeholder: { background: logoBgColor, fontSize: 18, color: isLightColor(logoBgColor) ? '#000' : '#fff' } }}>
            { !isOrgSwitching && (identity?.owner?.name.substring(0, 1) ?? 'A').toUpperCase() }
            { isOrgSwitching && <Loader size="xs" color={logoTextColor} /> }
          </Avatar>

          <Stack gap={0} style={{ overflow: 'hidden' }}>
            { <Text size="md" fw="bold" lh={1.4} truncate>{ identity?.name}</Text> }

            { !isOrgSwitching && <Text size="sm" c="dimmed" inline className={classes.highlightText}>
              { identity?.owner?.name ?? 'Admin' }
            </Text> }

            { isOrgSwitching && <Skeleton height={12} mt={5} width="100" radius="sm" /> }
          </Stack>

          { isMobile ? <IconChevronDown size={20} style={{ marginTop: 3 }} /> : <IconChevronRight size={20} style={{ marginTop: 3 }} /> }
        </Group>
      </Menu.Target>
      <Menu.Dropdown ref={ref}>
        <MenuItems />
      </Menu.Dropdown>
    </Menu>

    <Drawer opened={isMobile && opened}
            onClose={() => setMenu(!opened)}
            position="bottom"
            size="75%"
            withCloseButton={false}
            trapFocus
            zIndex={1000}
    >
      <MenuItems />
    </Drawer>
  </>;
}
