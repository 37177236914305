import { ICustomerRecord } from '@interfaces';
import { ActionIcon, Badge, Loader, Tooltip } from '@mantine/core';
import { IconAlertTriangle, IconEye } from '@tabler/icons-react';
import { useWithinDrawer } from '@components/ui/drawerManager/use-drawers/use-within-drawer';
import { useGetCustomerMatchingVisitorIDs } from '@components/hooks/useGetCustomerMatchingVisitorIDs';
import { useDisclosure } from '@mantine/hooks';
import { AssociatedCustomersDrawer } from '@components/ui/drawers/AssociatedCustomersDrawer';

interface Props {
  customer: ICustomerRecord;
}

export const VisitorIdBadge = ({ customer }: Props) => {
  const withinDrawerCtx = useWithinDrawer();
  const isWithinDrawer = withinDrawerCtx?.isWithin ?? false;
  const [opened, { open, close }] = useDisclosure(false);

  const { data, isLoading } = useGetCustomerMatchingVisitorIDs(customer.id, {
    enabled: !!customer.visitor_id,
  });

  const hasMultipleCustomers = data?.data.count > 1;

  if (!customer.visitor_id) {
    return <></>;
  }

  return <>
    <Tooltip label={`Visitor ID for this customer${hasMultipleCustomers ? `, with ${data?.data.count} associated customers` : ''}`}>
      <Badge color={hasMultipleCustomers ? 'orange.4' : 'gray'}
             bg={hasMultipleCustomers ? 'gray.0' : ''}
             fw={600}
             variant="outline"
             style={{ cursor: !isWithinDrawer ? 'pointer' : 'default' }}
             onClick={() => !isWithinDrawer && open()}
             leftSection={isLoading ? <Loader size={12} /> : hasMultipleCustomers ? <IconAlertTriangle size={13} strokeWidth={2} color="#ffa94d" /> : null}
             rightSection={!isWithinDrawer && <ActionIcon variant="transparent" color={hasMultipleCustomers ? '#ffa94d' : 'gray'} size="sm">
               <IconEye size={16} strokeWidth={1.5} />
             </ActionIcon>}
      >
        { customer.visitor_id }{ hasMultipleCustomers ? ` (${data?.data.count})` : null }
      </Badge>
    </Tooltip>

    <AssociatedCustomersDrawer opened={opened} close={close} customer_id={customer.id} />
  </>;
}
