import * as Yup from 'yup';
import { EMAIL_REGEX } from '@components/ui/EmailRegex';

export default (countryCode?: string) => Yup.object().shape({
  name: Yup.string().required('Enter a name'),
  email: Yup.string().matches(EMAIL_REGEX, 'Enter a valid email').required('Enter an email'),
  phone_number: Yup.lazy((_, { parent }) => {
    return Yup.string().when({
      is: (v) => {
        return v.replace(/\+[0-9]|\+/, '').trim() !== '' || parent.settings.notification_channels.includes('sms')
      },
      then: (schema) => {
        return schema
          .phone(countryCode ?? 'US' as any, 'Please enter a valid phone number')
          .required('Enter a phone number');
      }});
  }),
  settings: Yup.object({
    'notification_channels': Yup.array().min(1, 'Select at least one'),
    'notification_feedback': Yup.bool(),
    'notification_help': Yup.bool(),
    'notification_help_closed': Yup.bool(),
    'notification_refund': Yup.bool(),
    'notification_refund_types': Yup.array().when('notification_refund', {
      is: true,
      then: (schema) => schema.min(1, 'Select at least one')
    }),
    'notification_voice-message': Yup.bool(),
  }),
});
