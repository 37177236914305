import { Badge, Grid, Group, Loader, Stack, Text } from '@mantine/core';
import {
  ICustomerListItem,
  ICustomerRecord,
  IFeedbackListItem,
  IHelpListItem,
  IRefundListItem,
  IVoiceMessageListItem
} from '@interfaces';
import { TableRow } from '@components/table/TableRow';
import { CustomerCard } from '@components/data/cards/CustomerCard/CustomerCard';
import { LogicalFilter, useList } from '@refinedev/core';
import { AuditTable } from '@components/data/tables/AuditTable';
import { HelpTable } from '../../help/table/HelpTable';
import { TableFilterContext, useTableFilters } from '@components/table/TableFilter.context';
import { FeedbackTable } from '../../feedback/table/FeedbackTable';
import { RefundTable } from '../../refund/table/RefundTable';
import { Tabs } from '@components/ui/tabs/Tabs';
import { VoiceMessageTable } from '../../voice_message/table/VoiceMessageTable';
import { NotesCard } from '@components/data/cards/NotesCard';

type Props = {
  item: ICustomerListItem;
}

type TabContentProps = {
  record: ICustomerRecord;
}

const TabContent = ({ record }: TabContentProps) => {
  const { filters } = useTableFilters();

  const voiceMessageFilters = [{ field: 'is_archived', value: '*' }, ...filters]  as LogicalFilter[];

  const { data: feedbackResults, isLoading: isFeedbackLoading } = useList<IFeedbackListItem>({
    resource: 'feedback',
    filters
  });
  const { data: helpResults, isLoading: isHelpLoading } = useList<IHelpListItem>({
    resource: 'help',
    filters
  });
  const { data: refundResults, isLoading: isRefundLoading } = useList<IRefundListItem>({
    resource: 'refund',
    filters
  });
  const { data: voiceResults, isLoading: isVoiceLoading } = useList<IVoiceMessageListItem>({
    resource: 'voice_message',
    filters: voiceMessageFilters,
  });

  return <Tabs variant="outline" defaultValue="details">
    <Tabs.List>
      <Tabs.Tab value="details">
        <Text size="sm" fw="500">Details</Text>
      </Tabs.Tab>

      <Tabs.Tab value="help">
        <Group gap="xs">
          <Text size="sm" fw="500">Help</Text>
          { isHelpLoading ? <Loader size={16} /> : <Badge>{helpResults.total}</Badge>}
        </Group>
      </Tabs.Tab>

      <Tabs.Tab value="voice_messages">
        <Group gap="xs">
          <Text size="sm" fw="500">Calls</Text>
          { isVoiceLoading ? <Loader size={16} /> : <Badge>{voiceResults.total}</Badge>}
        </Group>
      </Tabs.Tab>

      <Tabs.Tab value="feedback">
        <Group gap="xs">
          <Text size="sm" fw="500">Feedback</Text>
          { isFeedbackLoading ? <Loader size={16} /> : <Badge>{feedbackResults.total}</Badge>}
        </Group>
      </Tabs.Tab>

      <Tabs.Tab value="refunds">
        <Group gap="xs">
          <Text size="sm" fw="500">Refunds</Text>
          { isRefundLoading ? <Loader size={16} /> : <Badge>{refundResults.total}</Badge>}
        </Group>
      </Tabs.Tab>

      <Tabs.Tab value="logs">
        <Group gap="xs">
          <Text size="sm" fw="500">Logs</Text>
          <Badge>{record.audits.length}</Badge>
        </Group>
      </Tabs.Tab>
    </Tabs.List>

    <Tabs.Panel value="details" pt="xs">
      <Grid>
        <Grid.Col span={{ lg: 6 }}>
          <Stack>
            <CustomerCard customer={record} />
          </Stack>
        </Grid.Col>

        <Grid.Col span={{ lg: 6 }}>
          <Stack>
            <NotesCard notes={record.notes} type="customer" id={record.id} />
          </Stack>
        </Grid.Col>
      </Grid>
    </Tabs.Panel>

    <Tabs.Panel value="help" pt="xs">
      { helpResults && <HelpTable initialData={helpResults} excludeColumns={['owner_name', 'customer_name']} /> }
    </Tabs.Panel>

    <Tabs.Panel value="feedback" pt="xs">
      { feedbackResults && <FeedbackTable initialData={feedbackResults} excludeColumns={['owner_name', 'customer_name']} /> }
    </Tabs.Panel>

    <Tabs.Panel value="refunds" pt="xs">
      { refundResults && <RefundTable initialData={refundResults} excludeColumns={['owner_name', 'customer_name']} /> }
    </Tabs.Panel>

    <Tabs.Panel value="voice_messages" pt="xs">
      { voiceResults && <TableFilterContext.Provider value={{ filters: voiceMessageFilters, excludeDefaultFilters: true }}>
        <VoiceMessageTable initialData={voiceResults} excludeColumns={['owner_name', 'name']} />
      </TableFilterContext.Provider>}
    </Tabs.Panel>

    <Tabs.Panel value="logs" pt="xs">
      <AuditTable audits={record.audits} />
    </Tabs.Panel>
  </Tabs>;
}

export default ({ item }: Props) => {
  return <TableRow<any> resource="customer" id={item.id}>
    {(record) => <TabContent record={record} />}
  </TableRow>;
}
