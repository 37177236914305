import { Button, Card, Group, Stack, Title, Text } from '@mantine/core';
import { useIdentity } from '@components/data/Identity.context';
import { useUpdate } from '@refinedev/core';
import { Point } from '@components/ui/Point';

export const RefundSettingsForm = () => {
  const { identity, refresh } = useIdentity();
  const { mutate: update, isLoading } = useUpdate();

  const owner = identity?.owner;

  const handleSubmit = async ({ active }) => {
    const data = {
      settings: {
        'refunds_active': active,
      }
    }

    await update({
      resource: 'owner/settings', id: identity.owner.id, values: data,
      successNotification: { type: 'success', message: 'Successfully updated settings.' }
    }, {
      onSuccess: refresh
    });
  };

  if (!owner) {
    return <></>;
  }

  return <>
    <Stack gap="xl" my="md">
      { !owner.settings.refunds_active && <>
        <Card withBorder p="md" radius="sm">
          <Card.Section p="md" withBorder>
            <Title order={5} fw="500">Activate</Title>
          </Card.Section>

          <Card.Section withBorder p="md">
            <Text size="sm">
              Activate requesting and issuing refunds using ZippyAssist. Your customers will be able to select from
              refund methods you have made available. Refund requests you approve will be issued on your behalf.
              You agree to reimburse 6L Inc. for all refunded amounts and transaction fees.
            </Text>
          </Card.Section>

          <Card.Section px="md" py="sm">
            <Group justify="right">
              <Button type="submit"
                      variant="light"
                      onClick={() => handleSubmit({ active: true })}
                      loading={isLoading}>
                I agree and activate
              </Button>
            </Group>
          </Card.Section>
        </Card>
      </>}

      { owner.settings.refunds_active && <>
        <Card withBorder p="md" radius="sm">
          <Card.Section p="md" withBorder>
            <Group justify="space-between">
              <Title order={5} fw="500">
                <Point color="#42ba96" /> Refunds enabled
              </Title>
              <Button type="submit"
                      color="red"
                      variant="light"
                      onClick={() => handleSubmit({ active: false })}
                      loading={isLoading}>
                Deactivate
              </Button>
            </Group>
          </Card.Section>
        </Card>
    </>}
    </Stack>
  </>;
}
