import { CustomerAvatar } from '@components/ui/CustomerAvatar';
import { ActionIcon, Drawer, Group, Title, Tooltip, useMantineTheme, Text } from '@mantine/core';
import { IconEye } from '@tabler/icons-react';
import { useDisclosure, useMediaQuery } from '@mantine/hooks';
import CustomerTableExpandedRow from '../../pages/customer/table/CustomerTableExpandedRow';
import { CustomerMarkedStatuses, ICustomerListItem } from '@interfaces';

interface Props {
  customer: {
    customer_id: number;
    customer_name: string;
    customer_marked_status: CustomerMarkedStatuses;
    customer_confirmed_at: string;
  };
}

export const CustomerPanelTrigger = ({ customer }: Props) => {
  const theme = useMantineTheme();
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`, false);
  const [opened, { open, close }] = useDisclosure(false);

  const id = customer.customer_id;
  const name = customer.customer_name;
  const marked_status = customer.customer_marked_status;
  const confirmed_at = customer.customer_confirmed_at;

  return <>
    <Group gap={4} w={ isMobile ? 'auto' : '100%'}>
      <CustomerAvatar customer={{ name, marked_status, confirmed_at }} compact />
      <Text truncate="end" flex={1} ml={5}>{ name }</Text>
      { id && <Tooltip label="View customer history" withArrow>
        <ActionIcon variant="subtle" color="gray" size="sm" onClick={open}>
          <IconEye size={16} strokeWidth={1.5} />
        </ActionIcon>
      </Tooltip>}
    </Group>

    <Drawer.Root opened={opened}
                 onClose={close}
                 position="right"
                 returnFocus={false}
                 zIndex={200}
                 size={ isMobile ? '100%' : '65%' }
    >
      <Drawer.Overlay />
      <Drawer.Content>
        <Drawer.Header>
          <Title order={3}>{ name }</Title>
          <Drawer.CloseButton />
        </Drawer.Header>
        <Drawer.Body>
          <CustomerTableExpandedRow item={{ id } as ICustomerListItem} />
        </Drawer.Body>
      </Drawer.Content>
    </Drawer.Root>
  </>;
}
