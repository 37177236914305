import { useApiUrl, useCustom } from '@refinedev/core';

export const useGetCustomerMatchingVisitorIDs = (customer_id, options = {}) => {
  const apiUrl = useApiUrl();

  return useCustom({ url: `${apiUrl}/customer/${customer_id}/matching_visitor_ids`, method: 'get', queryOptions: {
    cacheTime: 10800,
    refetchOnMount: false,
    ...options
  }});
};
