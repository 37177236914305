import { useResourceForm } from '@components/ui/form/ResourceForm.context';
import { IOwnerRecord } from '@interfaces';
import { ColorSwatchInput } from '@components/ui/colorGenerator/ColorSwatchInput';
import { Box, Divider, SimpleGrid, Stack, Switch, Title } from '@mantine/core';
import { TranslateEditor } from '@components/ui/editors/TranslateEditor';
import { Translatable } from '@components/ui';

export const defaultTagDetailsFormat = '<strong>{tag.name}</strong> {common.words.at} {tag.address}';

export const AppContentDetails = () => {
  const { form } = useResourceForm<IOwnerRecord>();

  return <>
    <ColorSwatchInput label="Theme Color" {...form.getInputProps('theme_color')} />

    <Divider my="xs" />

    <Stack>
      <Title order={5}>Equipment settings</Title>

      <SimpleGrid cols={{ md: 2 }} spacing="sm">
        <Switch label="Equipment details are hidden"
                description="Hides the formatted details on the equipment card in the App"
                offLabel="No" onLabel="Yes"
                {...form.getInputProps('settings.content_tag_details-hidden', { type: 'checkbox' })}
        />

        { !form.values.settings['content_tag_details-hidden'] && <Box mt={-4}>
          <TranslateEditor
            label="Equipment details format"
            originalText={defaultTagDetailsFormat}
            tokens={[
              '{tag.name}', '{tag.account_name}', '{tag.location_id}', '{tag.location_name}',
              '{tag.identifier}', '{tag.description}', '{tag.address}', '{tag.type}', '{common.words.at}',
            ]}
            {...form.getInputProps(`settings.content_tag_details-format`)}
          />
        </Box>}
      </SimpleGrid>
    </Stack>

    <Divider my="xs" />

    <Stack>
      <Title order={5}>Success settings</Title>

      <SimpleGrid cols={{ md: 2 }} spacing="sm">
        <Switch label="Show override success message (Blocked customers)"
                description="Show a different success message when the customer is blocked"
                offLabel="No" onLabel="Yes"
                {...form.getInputProps('settings.content_customer_show-blocked-message', { type: 'checkbox' })}
        />

        { form.values.settings['content_customer_show-blocked-message'] && <Box mt={-4}>
          <Translatable field="translations._lang_.steps.success.customer_blocked.text"
                        label="Override success message" />
        </Box>}
      </SimpleGrid>
    </Stack>

    <Divider my="xs" />

    <Stack>
      <Title order={5}>Feedback settings</Title>

      <SimpleGrid cols={{ md: 2 }} spacing="sm">
        <Switch label="Disable feedback collection"
                description="Hides the feedback link in the App"
                offLabel="No" onLabel="Yes"
                {...form.getInputProps('settings.feedback_disabled', { type: 'checkbox' })}
        />
      </SimpleGrid>
    </Stack>
  </>;
}
