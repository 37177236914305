import { EuiBasicTableColumn } from '@elastic/eui';
import { IVoiceMessageListItem } from '@interfaces';
import { DateTime } from '@components/ui/DateTime';
import { Menu } from '@mantine/core';
import { DeleteAction } from '@components/table/actions/DeleteAction';
import { ArchiveAction } from '@components/table/actions/ArchiveAction';
import { ResourceItem } from '@components/data/ResourceItem';
import { StatusSelect } from '@components/ui/form/StatusSelect';
import { ActionMenu } from '@components/table/actions/ActionMenu';
import { CreateRequestAction } from '@components/table/actions/CreateRequestAction';
import { ReprocessVoiceMessageAction } from './ReprocessVoiceMessageAction';
import { EditRequestAction } from '@components/table/actions/EditRequestAction';
import { UnarchiveAction } from '@components/table/actions/UnarchiveAction';
import { DeleteRequestLinkAction } from '@components/table/actions/DeleteRequestLinkAction';
import { Feature } from 'flagged';
import { CustomerPanelTrigger } from '@components/ui/CustomerPanelTrigger';

export default [
  {
    field: 'owner_name',
    name: 'Owner',
    width: '150',
    sortable: true,
    truncateText: true,
    permissions: ['admin']
  },
  {
    field: 'name',
    width: '180',
    name: 'Name',
    truncateText: true,
    sortable: true,
    render: (_name, record) => {
      return <CustomerPanelTrigger customer={{
        customer_id: record.customer_id,
        customer_name: record.customer_name ?? _name,
        customer_confirmed_at: record.customer_confirmed_at,
        customer_marked_status: record.customer_marked_status
      }} />
    },
  },
  {
    field: 'from_phone_number',
    width: '140',
    name: 'From Number',
    truncateText: true,
  },
  {
    field: 'to_phone_number',
    width: '140',
    name: 'To Number',
    truncateText: true,
  },
  {
    field: 'description',
    width: '100%',
    name: 'Summary',
    truncateText: true,
  },
  {
    field: 'created_at',
    name: 'Submitted',
    width: '165',
    sortable: true,
    dataType: 'date',
    render: (date) => <DateTime value={date} />
  },
  {
    field: 'status',
    name: 'Status',
    sortable: true,
    width: '140',
    render: (status, item) => {
      return <ResourceItem resource="voice_message" id={item.id}>
        {({ onUpdate }) => <>
          <StatusSelect value={status}
                        onChange={(status) => onUpdate({ status })}
                        options={[
                          { label: 'Read', value: 'Read' },
                          { label: 'Unread', value: 'Unread' },
                        ]} />
        </>}
      </ResourceItem>;
    }
  },
  {
    name: '',
    width: '40',
    actions: [
      {
        render: (record) => <ActionMenu id={record.id}>
          { record.help_id && <EditRequestAction type="help" label="Edit help" id={record.help_id} />}
          { !record.help_id && <CreateRequestAction type="help" label="Create help" />}

          { record.refund_id && <EditRequestAction type="refund" label="Edit refund" id={record.refund_id} />}
          { !record.refund_id && <CreateRequestAction type="refund" label="Create refund" />}

          { (!record.refund_id || !record.help_id) && !record.request_link_id && <CreateRequestAction type="link" label="Send request link" />}
          { record.request_link_id && <DeleteRequestLinkAction id={record.request_link_id} />}

          <Feature name="admin">
            <ReprocessVoiceMessageAction disabled={!!record.refund_id } />
          </Feature>

          <Menu.Divider />

          { !record.archived_at && <ArchiveAction />}
          { record.archived_at && <UnarchiveAction />}
          <DeleteAction target={`${record.name} (${record.from_phone_number})`} />
        </ActionMenu>
      },
    ]
  },
] as EuiBasicTableColumn<IVoiceMessageListItem>[];
