import { useApiUrl, useCustom } from '@refinedev/core';

export const useGetCustomerVisitorIDs = (customer_id, options = {}) => {
  const apiUrl = useApiUrl();

  return useCustom({ url: `${apiUrl}/customer/${customer_id}/visitor_ids`, method: 'get', queryOptions: {
    cacheTime: 10800,
    refetchOnMount: false,
    ...options,
  }});
};
