import { EuiAvatar, EuiCommentList, EuiFlexItem } from '@elastic/eui';
import { Button, Divider, Group } from '@mantine/core';
import { useForm } from '@mantine/form';
import { useApiUrl, useCreate, useCustom, useDelete, useUpdate } from "@refinedev/core";
import { INoteRecord } from '@interfaces';
import RichTextEditor from '@components/ui/editors/RichTextEditor';
import { NoteItem } from '@components/ui/notes/NoteItem';
import { useIdentity } from '@components/data/Identity.context';

interface Props {
  notes: INoteRecord[];
  type: 'request' | 'customer';
  id: number;
}

export const Notes = ({ notes, type, id }: Props) => {
  const { identity } = useIdentity();
  const { mutate: create, isLoading } = useCreate<INoteRecord>();
  const { mutate: update } = useUpdate<INoteRecord>();
  const { mutate: destroy } = useDelete<INoteRecord>();

  const form = useForm({
    validateInputOnChange: true,
    initialValues: {
      html: null,
    },
    validate: {
      html: (value) => {
        return !value;
      }
    }
  });

  const apiUrl = useApiUrl();
  const { data, refetch } = useCustom<INoteRecord[]>({ url: `${apiUrl}/${type}/${id}/notes`, method: 'get', queryOptions: {
    enabled: false
  }});

  const handleCreate = async (values) => {
    create({ resource: `${type}/${id}/notes`, values }, {  onSettled: () => refetch() });
    form.reset();
  };

  const handleUpdate = async (id, values) => {
    update({ resource: 'notes', id, values }, {  onSettled: () => refetch() });
  };

  const handleDelete = async (id) => {
    destroy({ resource: 'notes', id }, {  onSettled: () => refetch() });
  };

  const comments = (data?.data || notes).map((note, index) => {
    return <NoteItem key={`note-${index}`} note={note} onUpdate={handleUpdate} onDelete={handleDelete} />
  });

  return <>
    <EuiCommentList>{ comments }</EuiCommentList>

    { comments.length > 0 && <Divider my="md" /> }

    <Group align="top">
      <EuiAvatar size="m" name={identity.name} />

      <EuiFlexItem>
        <form onSubmit={form.onSubmit(handleCreate)}>
          <RichTextEditor {...form.getInputProps('html', { withError: false })}
                          placeholder="Compose a note..."
                          simpleControls={true}
          />

          <Button type="submit" disabled={!form.isValid()} loading={isLoading} mt="lg">Submit</Button>
        </form>
      </EuiFlexItem>
    </Group>
  </>;
}
