import { ICustomerListItem } from '@interfaces';
import { ResourceTable, ResourceTableProps } from '@components/table/ResourceTable';

import CustomerTableColumns from './CustomerTableColumns';
import CustomerTableExpandedRow from './CustomerTableExpandedRow';

export const CustomerTable = (props: ResourceTableProps<ICustomerListItem>) => {
  return <ResourceTable<ICustomerListItem>
    resource="customer"
    columns={CustomerTableColumns}
    onRenderExpanded={CustomerTableExpandedRow}
    {...props}
    tableProps={{
      ...props.tableProps,
      rowProps: (record) => ({
        'data-flagged': record.marked_status === 'Flagged',
        'data-blocked': record.marked_status === 'Blocked',
      })
    }}
  />;
}
