import { IRefundListItem } from '@interfaces';
import { ResourceTable, ResourceTableProps } from '@components/table/ResourceTable';

import RefundTableColumns from './RefundTableColumns';
import RefundTableExpandedRow from './RefundTableExpandedRow';

export const RefundTable = (props: ResourceTableProps<IRefundListItem>) => {
  return <ResourceTable<IRefundListItem>
    resource="refund"
    columns={RefundTableColumns}
    onRenderExpanded={RefundTableExpandedRow}
    excludeColumns={['reference']}
    {...props}
    tableProps={{
      ...props.tableProps,
      rowProps: (record) => ({
        'data-flagged': record.customer_marked_status === 'Flagged',
        'data-blocked': record.customer_marked_status === 'Blocked',
      })
    }}
  />;
}
