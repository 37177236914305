import { ResourceProps } from "@refinedev/core";
import {
  IconAdjustmentsHorizontal,
  IconDashboard,
  IconHelp,
  IconMoodSmile,
  IconReceiptRefund,
  IconSettings,
  IconTags,
  IconThumbUp,
  IconPhoneCall,
  IconClipboardData,
  IconDeviceMobile,
  IconWebhook,
  IconListDetails,
  IconUsers,
  IconPlugConnected,
  IconHomeCog,
  IconWallet,
  IconDeviceMobileCog,
  IconReportMoney,
  IconRobot,
  IconKey,
} from '@tabler/icons-react';

import { HelpList } from '../pages/help';
import { RefundList } from '../pages/refund';
import { VoiceMessageList } from '../pages/voice_message';
import { FeedbackList } from '../pages/feedback';
import { CustomerList } from '../pages/customer';
import { TagList, TagEdit } from '../pages/tag';
import { UserList, UserEdit } from '../pages/settings/users';
import { PaymentMethodEdit, PaymentMethodList } from '../pages/app_settings/payment_methods';
import { RefundMethodEdit, RefundMethodList } from '../pages/app_settings/refund_methods';
import { OwnerList, OwnerEdit } from '../pages/admin/owners';
import { RequestTypesList, RequestTypeEdit } from '../pages/app_settings/request_types';
import { MachineTypesList, MachineTypeEdit } from '../pages/app_settings/machine_types';
import { WebhookEdit, WebhookList } from '../pages/settings/webhooks';
import { WebhookLogList } from '../pages/settings/webhook_logs';
import { IntegrationEdit, IntegrationList } from '../pages/settings/integrations';
import { TagByAccountList } from '../pages/tag/listByAccount';
import { TransactionList } from '../pages/settings/transactions';
import { ApiKeyList, ApiKeyEdit } from 'src/pages/settings/api_keys';
import { RequestLinkList } from '../pages/request_links';
import { TagGroupEdit, TagGroupList } from '../pages/tag_group';

export const resourceConfigProvider: ResourceProps[] = [
  {
    name: 'dashboard',
    icon: <IconDashboard strokeWidth={1.5} />,
    meta: {
      label: 'Dashboard',
    },
    list: '/'
  },
  {
    name: 'help',
    icon: <IconHelp strokeWidth={1.5} />,
    meta: {
      label: 'Help Requests',
      defaultFilters: [
        { field: 'status', value: ['Open', 'In Progress', 'Closed'] },
      ],
    },
    list: {
      path: '/help',
      component: HelpList
    }
  },
  {
    name: 'refund',
    icon: <IconReceiptRefund strokeWidth={1.5} />,
    meta: {
      label: 'Refunds',
      defaultFilters: [
        { field: 'status', value: ['Pending', 'In Review'] }
      ],
    },
    list: {
      path: '/refund',
      component: RefundList
    }
  },
  {
    name: 'feedback',
    icon: <IconThumbUp strokeWidth={1.5} />,
    meta: {
      label: 'Feedback'
    },
    list: {
      path: '/feedback',
      component: FeedbackList
    }
  },
  {
    name: 'voice_message',
    icon: <IconPhoneCall strokeWidth={1.5} />,
    meta: {
      label: 'Voice Messages',
      defaultFilters: [
        { field: 'status', value: ['Read', 'Unread'] }
      ],
    },
    list: {
      path: '/voice_message',
      component: VoiceMessageList
    }
  },
  {
    name: 'customer',
    icon: <IconMoodSmile strokeWidth={1.5} />,
    meta: {
      label: 'Customers'
    },
    list: {
      path: '/customer',
      component: CustomerList
    }
  },
  {
    name: 'tag_system',
    icon: <IconTags strokeWidth={1.5} />,
    meta: {
      label: 'Equipment',
    },
    list: '/tag_system'
  },
  {
    name: 'tag',
    meta: {
      label: 'Items',
      parent: 'tag_system',
    },
    list: {
      path: '/tag',
      component: TagList
    },
    edit: {
      path: '/tag/edit/:id',
      component: TagEdit
    },
    create: {
      path: '/tag/create',
      component: TagEdit
    },
  },
  {
    name: 'tag_group',
    meta: {
      label: 'Groups',
      parent: 'tag_system',
    },
    list: {
      path: '/tag_group',
      component: TagGroupList
    },
    edit: {
      path: '/tag_group/edit/:id',
      component: TagGroupEdit
    },
    create: {
      path: '/tag_group/create',
      component: TagGroupEdit
    },
  },
  {
    name: 'tag_import',
    meta: {
      label: 'Equipment Import',
      parent: 'tag_system',
      hide: true,
    },
    list: '/tag/import'
  },
  {
    name: 'request_link',
    meta: {
      label: 'Request Links',
      hide: true,
    },
    list: {
      path: '/request_link',
      component: RequestLinkList
    },
  },
  {
    name: 'tag_account',
    icon: <IconClipboardData strokeWidth={1.5} />,
    meta: {
      label: 'Accounts'
    },
    list: {
      path: '/tag_account',
      component: TagByAccountList
    },
  },
  {
    name: 'app_settings',
    icon: <IconDeviceMobile strokeWidth={1.5} />,
    meta: {
      label: 'Customer App Settings',
    },
    list: '/app_settings'
  },
  {
    name: 'app_content',
    meta: {
      label: 'App Content',
      parent: 'app_settings',
    },
    list: '/app_settings/app_content'
  },
  {
    name: 'request_type',
    meta: {
      label: 'Request Types',
      parent: 'app_settings'
    },
    list: {
      path: '/app_settings/request_type',
      component: RequestTypesList
    },
    edit: {
      path: '/app_settings/request_type/edit/:id',
      component: RequestTypeEdit
    },
    create: {
      path: '/app_settings/request_type/create',
      component: RequestTypeEdit
    },
  },
  {
    name: 'machine_type',
    meta: {
      label: 'Machine Types',
      parent: 'app_settings'
    },
    list: {
      path: '/app_settings/machine_type',
      component: MachineTypesList
    },
    edit: {
      path: '/app_settings/machine_type/edit/:id',
      component: MachineTypeEdit
    },
    create: {
      path: '/app_settings/machine_type/create',
      component: MachineTypeEdit
    },
  },
  {
    name: 'payment_method',
    meta: {
      label: 'Payment Methods',
      parent: 'app_settings'
    },
    list: {
      path: '/app_settings/payment_method',
      component: PaymentMethodList
    },
    edit: {
      path: '/app_settings/payment_method/edit/:id',
      component: PaymentMethodEdit
    },
    create: {
      path: '/app_settings/payment_method/create',
      component: PaymentMethodEdit
    },
  },
  {
    name: 'refund_method',
    meta: {
      label: 'Refund Methods',
      parent: 'app_settings'
    },
    list: {
      path: '/app_settings/refund_method',
      component: RefundMethodList
    },
    edit: {
      path: '/app_settings/refund_method/edit/:id',
      component: RefundMethodEdit
    },
    create: {
      path: '/app_settings/refund_method/create',
      component: RefundMethodEdit
    },
  },
  {
    name: 'settings',
    icon: <IconAdjustmentsHorizontal strokeWidth={1.5} />,
    meta: {
      label: 'Settings',
    },
    list: '/settings'
  },
  {
    name: 'organization',
    icon: <IconHomeCog size={16} strokeWidth={1.5} />,
    meta: {
      label: 'Organization',
      parent: 'settings'
    },
    list: '/settings/organization'
  },
  {
    name: 'automations',
    icon: <IconRobot size={16} strokeWidth={1.5} />,
    meta: {
      label: 'Automations',
      parent: 'settings',
    },
    list: '/settings/automations'
  },
  {
    name: 'demo_data',
    meta: {
      label: 'Demo Data',
      parent: 'settings'
    },
    list: '/settings/demo_data'
  },
  {
    name: 'user',
    icon: <IconUsers size={16} strokeWidth={1.5} />,
    meta: {
      label: 'Users',
      parent: 'settings'
    },
    list: {
      path: '/settings/user',
      component: UserList
    },
    edit: {
      path: '/settings/user/edit/:id',
      component: UserEdit
    },
    create: {
      path: '/settings/user/create',
      component: UserEdit
    },
  },
  {
    name: 'voice_messages',
    icon: <IconDeviceMobileCog size={16} strokeWidth={1.5} />,
    meta: {
      label: 'Voice Messages',
      parent: 'settings'
    },
    list: '/settings/voice_messages',
  },
  {
    name: 'integration',
    icon: <IconPlugConnected size={16} strokeWidth={1.5} />,
    meta: {
      label: 'Integrations',
      parent: 'settings'
    },
    list: {
      path: '/settings/integration',
      component: IntegrationList
    },
    edit: {
      path: '/settings/integration/edit/:id',
      component: IntegrationEdit
    },
    create: {
      path: '/settings/integration/create',
      component: IntegrationEdit
    },
  },
  {
    name: 'webhook',
    icon: <IconWebhook size={16} strokeWidth={1.5} />,
    meta: {
      label: 'Webhooks',
      parent: 'settings'
    },
    list: {
      path: '/settings/webhook',
      component: WebhookList
    },
    edit: {
      path: '/settings/webhook/edit/:id',
      component: WebhookEdit
    },
    create: {
      path: '/settings/webhook/create',
      component: WebhookEdit
    },
  },
  {
    name: 'api_key',
    icon: <IconKey size={16} strokeWidth={1.5} />,
    meta: {
      label: 'API Keys',
      parent: 'settings'
    },
    list: {
      path: '/settings/api_key',
      component: ApiKeyList
    },
    edit: {
      path: '/settings/api_key/edit/:id',
      component: ApiKeyEdit
    },
    create: {
      path: '/settings/api_key/create',
      component: ApiKeyEdit
    },
  },
  {
    name: 'webhook_logs',
    icon: <IconListDetails size={16} strokeWidth={1.5} />,
    meta: {
      label: 'Webhook Logs',
      parent: 'settings',
      hide: true,
    },
    list: {
      path: '/settings/webhook_logs',
      component: WebhookLogList
    },
  },
  {
    name: 'wallet',
    icon: <IconWallet size={16} strokeWidth={1.5} />,
    meta: {
      label: 'Wallet',
      parent: 'settings'
    },
    list: '/settings/wallet'
  },
  {
    name: 'transactions',
    icon: <IconReportMoney size={16} strokeWidth={1.5} />,
    meta: {
      label: 'Transactions',
      parent: 'settings'
    },
    list: {
      path: '/settings/transactions',
      component: TransactionList
    },
  },
  {
    name: 'admin',
    icon: <IconSettings strokeWidth={1.5} />,
    meta: {
      label: 'Admin',
    },
    list: '/admin'
  },
  {
    name: 'playground',
    meta: {
      label: 'Playground',
      parent: 'admin'
    },
    list: '/admin/playground'
  },
  {
    name: 'owner',
    meta: {
      label: 'Owners',
      parent: 'admin',
    },
    list: {
      path: '/admin/owner',
      component: OwnerList
    },
    edit: {
      path: '/admin/owner/edit/:id',
      component: OwnerEdit
    },
    create: {
      path: '/admin/owner/create',
      component: OwnerEdit
    },
  },
  {
    name: 'profile',
    meta: {
      label: 'Profile',
    },
    list: '/profile'
  },
];
