import { Button, Divider, Grid, Group, Stack, Textarea, Select, Switch } from '@mantine/core';
import { useForm, yupResolver } from '@mantine/form';
import * as Yup from 'yup';
import { CustomerCard } from '@components/data/cards/CustomerCard/CustomerCard';
import { useUpdateOrCreate } from '@components/hooks';
import { useRequestState } from '@components/ui/drawers/Requests/RequestState.context';
import { useDrawers } from '@components/ui/drawerManager';
import { TagAlert, TagDetailsCard, TagSelector } from '@components/ui/drawers/Requests/TagComponents';
import { useInvalidate } from '@refinedev/core';
import { RequestLinkNotificationPreview } from '@components/ui/notificationPreviewers/RequestLinkNotificationPreview';

export const LinkRequestForm = () => {
  const { closeAll } = useDrawers();
  const invalidate = useInvalidate();
  const { customer, setCustomer, tag, reset, request, requestLink, help, refund } = useRequestState();
  const { mutate, isLoading: isSaving } = useUpdateOrCreate();

  const schema = Yup.object().shape({
    type: Yup.string().required('Select a type'),
  });

  const form = useForm({
    validate: yupResolver(schema),
    initialValues: {
      customer_id: customer.id,
      type: help ? 'Refund' : refund ? 'Help' : '',
      skip_pre_auth: false,
      message: '',
    }
  });

  const handleSubmit = (values) => {
    values.request_id = request?.id ?? null;
    values.tag_id = tag?.id ?? null;

    mutate({ resource: 'request_link', id: requestLink?.id, values }, {
      onSuccess: async () => {
        closeAll();

        await invalidate({ resource: 'help', invalidates: ['all'] });
        await invalidate({ resource: 'refund', invalidates: ['all'] });
        await invalidate({ resource: 'voice_message', invalidates: ['all'] });
      }
    });
  };

  const options = [
    ( !help ? { value: 'Help', label: 'Help' } : null),
    ( !refund ? { value: 'Refund', label: 'Refund Only' } : null),
  ].filter(v => !!v);

  return <>
    <form onSubmit={form.onSubmit(handleSubmit)}>
      <Grid>
        <Grid.Col span={{ lg: 6 }}>
          <Stack>
            <TagSelector />

            <TagAlert />

            <Select label="Type"
                    placeholder="Select an option"
                    data={options}
                    {...form.getInputProps('type')}
            />

            { form.values.type === 'Refund' && <>
              <Switch label="Ignore pre-auth delay"
                      description="Allows the customer to submit a refund request without entering the pre-auth holding period"
                      offLabel="No" onLabel="Yes"
                      {...form.getInputProps('skip_pre_auth', { type: 'checkbox' })}
              />
            </>}

            <RequestLinkNotificationPreview customer={customer}
                                            type={form.values.type}
                                            message={form.values.message}
            >
              <Textarea
                label="Additional message"
                placeholder="Optional message"
                description="Enter a brief message to include with the invite message sent to customer."
                {...form.getInputProps('message')}
              />
            </RequestLinkNotificationPreview>
          </Stack>
        </Grid.Col>

        <Grid.Col span={{ lg: 6 }}>
          <Stack mt="sm">
            <CustomerCard customer={customer} onUpdated={setCustomer} />
            <TagDetailsCard />
          </Stack>
        </Grid.Col>
      </Grid>

      <Divider my="md" />

      <Group wrap="nowrap" gap="xs" justify="right">
        <Button variant="light" color="gray.8" onClick={() => requestLink ? closeAll() : reset()}>Cancel</Button>
        <Button variant="filled" type="submit" loading={isSaving}>
          Save
        </Button>
      </Group>
    </form>
  </>;
};
