import { useEffect, useRef, useState } from 'react';
import { ActionIcon, Flex, InputBase, InputBaseProps, Loader } from '@mantine/core';
import { useDidUpdate } from '@mantine/hooks';
import { IconCheck, IconEdit, IconX } from '@tabler/icons-react';
import type { UseFormReturnType } from '@mantine/form/lib/types';

type Props = {
  form: UseFormReturnType<any>;
  field: string;
  isLoading?: boolean;
  isEditable?: boolean;
  renderRoot?: (props: any) => any;
} & InputBaseProps

export const EditableInput = ({ form, field, isLoading = false, isEditable = true, ...props }: Props) => {
  const [isReadOnly, setIsReadOnly] = useState(true);
  const [previousValue, setPreviousValue] = useState<string | number>('');
  const [currentValue, setCurrentValue] = useState<string | number>('');
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setPreviousValue(form.values[field]);
    setCurrentValue(form.values[field]);
  }, []);

  useDidUpdate(() => {
    setCurrentValue(form.values[field]);
  }, [form.values[field]]);

  useDidUpdate(() => {
    if (isReadOnly && form.errors[field]) {
      setIsReadOnly(false);
    }
  }, [form.errors]);

  const onEdit = () => {
    setIsReadOnly(false);
    setTimeout(() => {
      inputRef.current.focus();
      inputRef.current.select();
    }, 0);

    if (!form.errors[field]) {
      setPreviousValue(form.values[field]);
    }

    form.setFieldError('is_editing', true);
  };

  const onConfirm = () => {
    form.clearFieldError('is_editing');
    form.setFieldValue(field, currentValue);

    if (!form.validate().hasErrors) {
      setPreviousValue(form.values[field]);
      setIsReadOnly(true);
    }
  };

  const onCancel = () => {
    setIsReadOnly(true);
    form.clearFieldError('is_editing');

    setCurrentValue(previousValue);

    if (previousValue !== currentValue) {
      form.setFieldValue(field, previousValue);
      form.validate();
    }
  };

  const actions = () => {
    if (!isEditable) {
      return <></>;
    }

    if (isLoading) {
      return <Loader size="xs" />;
    }

    if (isReadOnly) {
      return <ActionIcon variant="subtle" size="sm" color="blue">
        <IconEdit size={20} strokeWidth={1.5} onClick={onEdit} />
      </ActionIcon>
    }

    return <Flex wrap="nowrap" gap={5}>
      <ActionIcon variant="light" size="sm" color="green" disabled={!!form.errors[field] && currentValue === form.values[field]}>
        <IconCheck size={20} strokeWidth={1.5} onClick={onConfirm} />
      </ActionIcon>
      <ActionIcon variant="light" size="sm" color="red">
        <IconX size={20} strokeWidth={1.5} onClick={onCancel} />
      </ActionIcon>
    </Flex>;
  };

  return <>
    <InputBase ref={inputRef}
               readOnly={isReadOnly}
               rightSectionWidth={isReadOnly ? 33 : 63}
               rightSection={actions()}
               value={currentValue ?? ''}
               autoComplete="off"
               onKeyDown={(e) => {
                 if (
                   (e.key === 'Enter') && (
                     !form.errors[field] ||
                     !!form.errors[field] && currentValue !== form.values[field]
                   )
                 ) {
                   onConfirm();
                   e.preventDefault();
                 }

                 if (e.key === 'Escape') {
                   onCancel();
                   e.preventDefault();
                 }
               }}
               onChange={(e) => {
                 // @ts-ignore
                 setCurrentValue(e?.currentTarget?.value ?? e)
               }}
               error={form.errors[field]}
               {...props} />
  </>;
};
