import React, { useRef, useState } from 'react';
import { PhoneInputProps, usePhoneInput, defaultCountries, FlagImage } from 'react-international-phone';
import { Button, Combobox, Group, Text, ScrollArea, TextInput, useCombobox } from '@mantine/core';

interface PhoneProps extends PhoneInputProps {
  label: string;
  onChange: (value: string) => void;
  hideCountryDropdown?: boolean;
}

export const PhoneInput = ({ value, onChange, hideCountryDropdown = false, ...props }: PhoneProps) => {
  const viewportRef = useRef<HTMLDivElement>(null);
  const [search, setSearch] = useState('');

  const phoneInput = usePhoneInput({
    defaultCountry: 'us',
    value,
    onChange: (data) => {
      onChange && onChange(data.phone);
    },
  });

  const combobox = useCombobox({
    onDropdownClose: () => {
      combobox.resetSelectedOption();
      phoneInput.inputRef.current?.focus();
      setSearch('');
    },
    onDropdownOpen: () => {
        combobox.focusSearchInput();
        setTimeout(() => {
          const activeId = combobox.selectActiveOption();
          viewportRef.current?.querySelector(`[id="${activeId}"]`)?.scrollIntoView({ block: 'nearest' });
        }, 1);
    },
  });

  const options = defaultCountries
    .filter((item) => {
      return item[0].toLowerCase().includes(search.toLowerCase().trim()) ||
             item[1].toLowerCase().includes(search.toLowerCase().trim());
    })
    .filter((item) => {
      return item[1] === 'ca' || item[1] === 'us';
    })
    .map((item, i) => (
      <Combobox.Option key={i} value={item[1]} active={item[1] === phoneInput.country.iso2}>
        <Group gap="xs" wrap="nowrap">
          <FlagImage iso2={item[1]} size="24px" />
          <Text size="sm">{ item[0] }</Text>
        </Group>
      </Combobox.Option>
    ));

  const countryDropdown = <Combobox
    withinPortal
    withArrow
    shadow="sm"
    width={240}
    store={combobox}
    onOptionSubmit={(val) => {
      phoneInput.setCountry(val);
      combobox.closeDropdown();
    }}
  >
    <Combobox.Target>
      <Button variant="light" px="xs" size="xs" radius={6} onClick={() => combobox.toggleDropdown()}>
        { phoneInput.country?.iso2.toUpperCase() }
      </Button>
    </Combobox.Target>

    <Combobox.Dropdown>
      <Combobox.Search
        value={search}
        onChange={(event) => setSearch(event.currentTarget.value)}
        placeholder="Filter by country"
      />
      <Combobox.Options>
        <ScrollArea.Autosize type="scroll" mah={280} viewportRef={viewportRef}>
          {options.length > 0 ? options : <Combobox.Empty>Nothing found</Combobox.Empty>}
        </ScrollArea.Autosize>
      </Combobox.Options>
    </Combobox.Dropdown>
  </Combobox>;

  return (
    <TextInput
      type="tel"
      value={phoneInput.inputValue}
      width={200}
      ref={phoneInput.inputRef}
      onChange={phoneInput.handlePhoneValueChange}
      leftSectionWidth={hideCountryDropdown ? 0: 45}
      leftSection={hideCountryDropdown ? null : countryDropdown}
      {...props}
    />
  );
};
