import { useGetCustomerVisitorIDs } from '@components/hooks/useGetCustomerVisitorIDs';
import { LogicalFilter } from '@refinedev/core';
import { TableFilterContext } from '@components/table/TableFilter.context';
import { CustomerTable } from '../../../pages/customer/table/CustomerTable';
import { CustomerAvatar } from '@components/ui/CustomerAvatar';
import { WithinDrawerContext } from '@components/ui/drawerManager/use-drawers/use-within-drawer';
import { Drawer, Group, Title, useMantineTheme, Stack } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { TableConfigurationContext } from '@components/table/TableConfiguration.context';
import { EuiBasicTableColumn } from '@elastic/eui';
import { ICustomerListItem } from '@interfaces';

type Props = {
  opened: boolean;
  close: () => void;
  customer_id: number;
}

export const AssociatedCustomersDrawer = ({ opened, close, customer_id }: Props) => {
  const theme = useMantineTheme();
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`, false);
  const { data } = useGetCustomerVisitorIDs(customer_id, { enabled: opened });

  const filters = [{
    field: `visitor_id`,
    value: data?.data?.join(','),
  }] as LogicalFilter[];

  const columns = [
    {
      field: 'visitor_id',
      name: 'Visitor ID',
      width: '210',
      mobileOptions: {
        width: '100%'
      },
    },
    {
      field: 'name',
      name: 'Name',
      sortable: true,
      width: '240',
      mobileOptions: {
        width: '100%'
      },
      render: (_name, record) => <CustomerAvatar customer={record} />,
    },
    {
      field: 'phone_number',
      name: 'Mobile',
      truncateText: true,
      width: '130',
      sortable: true,
    },
    {
      field: 'email',
      name: 'Email',
      truncateText: true,
      sortable: true,
    },
    {
      field: 'help_requests_count',
      name: 'Help',
      width: '80',
      align: 'center',
      sortable: true,
    },
    {
      field: 'voice_messages_count',
      name: 'Calls',
      width: '70',
      align: 'center',
      sortable: true,
    },
    {
      field: 'feedback_requests_count',
      name: 'Feedback',
      width: '90',
      align: 'center',
      sortable: true,
    },
    {
      field: 'refunds_count',
      name: 'Refunds',
      width: '80',
      align: 'center',
      sortable: true,
    },
    {
      field: 'refunds_sum_amount',
      name: 'Requested',
      width: '100',
      align: 'center',
      sortable: true,
      render: (amount) => `$${amount || 0}`,
    },
  ] as EuiBasicTableColumn<ICustomerListItem>[];

  return <WithinDrawerContext.Provider value={{ isWithin: true }}>
    <TableConfigurationContext.Provider value={{ initialPageSize: 15 }}>
      <TableFilterContext.Provider value={{ filters, excludeDefaultFilters: true }}>
        <Drawer opened={opened}
                onClose={close}
                position="right"
                withCloseButton={false}
                size={ isMobile ? '100%' : '75%' }
        >
          <Stack gap={0} mb="sm" >
            <Group justify="space-between">
              <Title order={5}>Associated Customers</Title>

              <Drawer.CloseButton />
            </Group>
          </Stack>

          { data && <CustomerTable columns={columns} />}
        </Drawer>
      </TableFilterContext.Provider>
    </TableConfigurationContext.Provider>
  </WithinDrawerContext.Provider>;
};
