import { createContext, useContext } from 'react';

type WithinDrawerContextProps = {
  isWithin: boolean;
};

export const WithinDrawerContext = createContext<WithinDrawerContextProps>(null);

export function useWithinDrawer() {
  return useContext(WithinDrawerContext);
}
