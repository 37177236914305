import { StatusIndicator } from '@components/ui/StatusIndicator';
import { Box } from '@mantine/core';

interface Props {
  rating?: number;
}

export const RatingIndicator = ({ rating }: Props) => {
  if (!rating) return <Box ml={5}>&mdash;</Box>;

  let status = 'Success';

  rating = Number(rating);

  if (rating <= 6) {
    status = 'Failure';
  } else if (rating >=7 && rating <= 8) {
    status = 'Neutral';
  }

  let ratingWord = 'excellent';

  if (rating <= 4) {
    ratingWord = 'poor';
  } else if (rating === 5) {
    ratingWord = 'average';
  } else if (rating > 5 && rating <= 7) {
    ratingWord = 'okay';
  } else if (rating === 8) {
    ratingWord = 'good';
  } else if (rating === 9) {
    ratingWord = 'great';
  }

  return <>
    <StatusIndicator status={status} includeWord={false} /> {rating} - {ratingWord}
  </>;
}
