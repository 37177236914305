import { createFormContext } from '@mantine/form';

interface RefundRequestFormValues {
  status: string;
  amount: number;
  approved_decision_note: string;
  declined_decision_note: string;
}

export const [RefundRequestFormProvider, useRefundRequestFormContext, useRefundRequestForm] = createFormContext<RefundRequestFormValues>();
