import { CollapsableCard } from '@components/ui';
import { ICustomerRecord } from '@interfaces';
import { Button, Divider, Group, Select, SimpleGrid, TextInput } from '@mantine/core';
import { useForm, yupResolver } from '@mantine/form';
import { useCreate } from '@refinedev/core';
import 'yup-phone-lite';
import { useRequestState } from '@components/ui/drawers/Requests/RequestState.context';
import { useIdentity } from '@components/data/Identity.context';
import { customerSchema } from '@components/data/cards/CustomerCard/CustomerCard';
import { setFormErrorsFromResponse } from '@components/ui/form/ResourceForm';

export const CreateCustomer = () => {
  const { owner } = useIdentity();
  const { reset, setCustomer, customer } = useRequestState();
  const { mutate: create, isLoading: isSaving } = useCreate<ICustomerRecord>();

  const form = useForm<Partial<ICustomerRecord>>({
    validateInputOnBlur: true,
    validate: yupResolver(customerSchema(owner)),
    initialValues: {
      locale: 'en',
      name: customer.name,
      email: customer.email,
      phone_number: customer.phone_number,
    }
  });

  const handleSubmit = async (values) => {
    create({ resource: 'customer', values }, {
      onSuccess: ({ data }) => setCustomer(data),
      onError: ({ response }) => {
        setFormErrorsFromResponse(response, form);
      },
    });
  };

  return <>
    <form onSubmit={form.onSubmit(handleSubmit)}>
      <SimpleGrid cols={{ md: 2 }} spacing="xs">
        <CollapsableCard label="New Customer"
                                collapsable={false}
        >
          <SimpleGrid cols={{ md: 2 }} spacing="xs">
            <TextInput label="Name" {...form.getInputProps('name')} />
            <TextInput label="Email (optional)" {...form.getInputProps('email')} />
            <TextInput label="Mobile (optional)" {...form.getInputProps('phone_number')} />

            <Select label="Language"
                    data={[
                      { value: 'en', label: 'English' },
                      { value: 'es', label: 'Español' },
                      { value: 'fr', label: 'French' },
                    ]}
                    {...form.getInputProps('locale')}
            />
          </SimpleGrid>
        </CollapsableCard>
      </SimpleGrid>

      <Divider my="md" />

      <Group wrap="nowrap" gap="xs" justify="right">
        <Button variant="light" color="gray.8" onClick={reset}>Cancel</Button>
        <Button variant="filled" type="submit" loading={isSaving}>Create</Button>
      </Group>
    </form>
  </>;
}
