import type { NextPage } from 'next';
import type { AppProps } from 'next/app';
import Head from 'next/head';
import { Refine } from '@refinedev/core';
import routerProvider from '@refinedev/nextjs-router';
import { UnsavedChangesNotifier } from '@refinedev/nextjs-router/pages';
import { EuiProvider } from '@elastic/eui';
import { MantineProvider } from '@mantine/core';
import { Notifications } from '@mantine/notifications';
import TimeAgo from 'javascript-time-ago';
import { IntercomProvider } from 'react-use-intercom';
import { IProfileRecord } from '@interfaces';
import theme from '../theme';
import { FlagsProvider } from 'flagged';

import {
  authProvider,
  accessControlProvider,
  zippyDataProvider,
  resourceConfigProvider,
  notificationProvider, featureFlagControlProvider
} from 'src/providers';

import '@elastic/eui/dist/eui_theme_light.min.css';
import '@mantine/core/styles.css';
import '@mantine/dropzone/styles.css';
import '@mantine/tiptap/styles.css';
import '@mantine/notifications/styles.css';
import '@mantine/charts/styles.css';
import '@mantine/nprogress/styles.css';
import '@mantine/dates/styles.css';
import 'src/styles/global.css';

import en from 'javascript-time-ago/locale/en.json';

import { Layout } from '@components/layout/Layout';
import { RouterTransition } from '@components/ui/RouterTransition';
import { ModalsProvider } from '@mantine/modals';
import { ConfirmPasswordModal } from '@components/ui/modals/ConfirmPasswordModal';
import { IdentityProvider } from '@components/data/Identity.context';
import { ApiKeyDetailsModals } from '@components/ui/modals/ApiKeyDetailsModal';
import { DrawersProvider } from '@components/ui/drawerManager';
import { RequestDrawer } from '@components/ui/drawers/Requests/RequestDrawer';

export const API_URL = `${process.env.NEXT_PUBLIC_API_URL}/api/v1`;
export const NODE_API_URL = `${process.env.NEXT_PUBLIC_NODE_API_URL}`;

TimeAgo.addLocale(en);

export type ExtendedNextPage = NextPage & {
  noLayout?: boolean;
};

type ExtendedAppProps = AppProps & {
  Component: ExtendedNextPage;
  pageProps: {
    profile?: IProfileRecord
  };
};

function MyApp({ Component, pageProps }: ExtendedAppProps) {
  const renderComponent = () => {
    if (Component.noLayout) {
      return <IntercomProvider appId={process.env.NEXT_PUBLIC_INTERCOM_APP_ID} autoBoot>
        <Component {...pageProps} />
      </IntercomProvider>;
    }

    return <IdentityProvider initialIdentity={pageProps.profile}>
      <FlagsProvider features={featureFlagControlProvider(pageProps.profile)}>
        <IntercomProvider appId={process.env.NEXT_PUBLIC_INTERCOM_APP_ID}>
          <ModalsProvider modals={{
            confirmPassword: ConfirmPasswordModal,
            apiKeyDetails: ApiKeyDetailsModals,
          }} modalProps={{ centered: true }}>
            <DrawersProvider drawers={{
              request: RequestDrawer
            }}>
              <Layout><Component {...pageProps} /></Layout>
            </DrawersProvider>
          </ModalsProvider>
        </IntercomProvider>
      </FlagsProvider>
    </IdentityProvider>;
  };

  return <>
    <Head>
      <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
    </Head>
    <EuiProvider colorMode="light" modify={{ base: 16 }}>
      <MantineProvider theme={theme}>
        <Notifications position="top-right" />
        <Refine
          routerProvider={routerProvider}
          dataProvider={{
            default: zippyDataProvider(API_URL)
          }}
          authProvider={authProvider(pageProps.profile)}
          accessControlProvider={accessControlProvider(pageProps.profile)}
          notificationProvider={notificationProvider}
          resources={resourceConfigProvider}
          options={{
            disableTelemetry: true,
            warnWhenUnsavedChanges: true,
            reactQuery: {
              devtoolConfig: {
                position: 'bottom-left'
              }
            }
          }}
        >
          <RouterTransition />
          <UnsavedChangesNotifier />

          { renderComponent() }
        </Refine>
      </MantineProvider>
    </EuiProvider>
  </>;
}

export default MyApp;
