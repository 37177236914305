import { useResourceForm } from '@components/ui/form/ResourceForm.context';
import { IOwnerRecord } from '@interfaces';
import { Divider, Text, SimpleGrid, Switch, Title, Stack, NumberInput } from '@mantine/core';
import { SortableMultiSelect } from '@components/ui/sortableMultiSelect/SortableMultiSelect';

export const OrgSettingsForm = () => {
  const { form } = useResourceForm<IOwnerRecord>();

  return <>
    <Title order={5} mb="xs">Owner email notifications</Title>

    <SimpleGrid cols={{ md: 2 }} spacing="xl">
      <Switch label="Include customer in Reply-to Address"
              description="This will include the customers email and name in all request type email notifications"
              offLabel="No" onLabel="Yes"
              {...form.getInputProps('settings.request_email_include-customer-in-reply-to', { type: 'checkbox' })}
      />

      <SortableMultiSelect label="Additional subject line tags"
                           description="This will include additional information to the end of the email subject line of request notifications"
                           placeholder="Select tags..."
                           data={[
                             {
                               group: 'Equipment',
                               items: [
                                 { value: 'tag_identifier', label: 'Equipment ID' },
                                 { value: 'tag_location_name', label: 'Equipment Location Name' },
                                 { value: 'tag_address', label: 'Equipment Address' },
                                 { value: 'tag_account_name', label: 'Equipment Account' },
                                 { value: 'tag_description', label: 'Equipment Description' },
                               ],
                             },
                             {
                               group: 'Customer',
                               items: [
                                 { value: 'customer_id', label: 'Customer ID' },
                                 { value: 'customer_name', label: 'Customer Name' },
                                 { value: 'customer_email', label: 'Customer Email' },
                               ],
                             },
                           ]}
                           {...form.getInputProps('settings.request_email_additional-subject-line-references')} />

      <Switch label="Include voice message as an attachment"
              description="This will include the voice message audio file as an attachment in email notifications"
              offLabel="No" onLabel="Yes"
              {...form.getInputProps('settings.request_email_include-audio-attachment', { type: 'checkbox' })}
      />
    </SimpleGrid>

    <Divider my="md" />

    <Stack gap={0} mb="xs">
      <Title order={5}>Input settings</Title>
      <Text size="sm" c="dimmed">These settings apply to all help requests</Text>
    </Stack>

    <SimpleGrid cols={{ md: 2 }} spacing="lg">
      <Switch label="Description input requirement"
              description="Marks the field as required or optional"
              offLabel="No" onLabel="Yes"
              {...form.getInputProps('require_description_flg', { type: 'checkbox' })}
      />

      <Switch label="Photo input requirement"
              description="Marks the field as required or optional"
              offLabel="No" onLabel="Yes"
              {...form.getInputProps('require_image_flg', { type: 'checkbox' })}
      />

      <Switch label="Machine ID input requirement"
              description="Marks the field as required or optional"
              offLabel="No" onLabel="Yes"
              {...form.getInputProps('require_machine_id_title_flg', { type: 'checkbox' })}
      />

      <Switch label="Machine Location input"
              description="When turned on, will prompt the customer to enter machine location info when no equipment ID is provided."
              offLabel="No" onLabel="Yes"
              {...form.getInputProps('settings.machine-location_field_active', { type: 'checkbox' })}
      />
    </SimpleGrid>

    <Divider my="md" />

    <Stack gap={0} mb="xs">
      <Title order={5}>Input settings (Refunds)</Title>
      <Text size="sm" c="dimmed">These settings only apply to help requests that are associated with a refund</Text>
    </Stack>

    <SimpleGrid cols={{ sm: 2 }} spacing="lg">
      <Switch label="Machine ID input requirement"
              description="Marks the field as required or optional when refunds are active"
              offLabel="No" onLabel="Yes"
              {...form.getInputProps('require_machine_id_in_refund_flg', { type: 'checkbox' })}
      />

      <Switch label="Photo input requirement"
              description="Marks the field as required or optional when refunds are active"
              offLabel="No" onLabel="Yes"
              {...form.getInputProps('require_photo_in_refund_flg', { type: 'checkbox' })}
      />

      <Switch label="Confirm customer phone number"
              description="When turned on, will send a confirmed SMS to the customer's phone number, when requesting a refund, if number is unverified"
              offLabel="No" onLabel="Yes"
              {...form.getInputProps('settings.customers_confirmation_active', { type: 'checkbox' })}
      />
    </SimpleGrid>

    <Divider my="md" />

    <Stack gap={0} mb="xs">
      <Title order={5}>Draft expiration settings (Refunds)</Title>
      <Text size="sm" c="dimmed">These configuration settings apply to draft refunds. (Drafts are requests where payment is subject to pre-authorization, where a temporary hold on funds applies. You should configure these settings to expire old drafts, where the temporary hold will have long expired and the draft is unlikely to be progressed by the customer)</Text>
    </Stack>

    <SimpleGrid cols={{ sm: 2 }} spacing="lg">
      <Switch label="Auto-expire drafts"
              description="Automatically expire drafts after a certain number of days"
              offLabel="No" onLabel="Yes"
              {...form.getInputProps('settings.refunds_expiration_active', { type: 'checkbox' })}
      />

      { form.values.settings.refunds_expiration_active && <>
        <Switch label="Auto-close help request"
                description="Close the help request when a draft is expired"
                offLabel="No" onLabel="Yes"
                {...form.getInputProps('settings.refunds_expiration_auto-close-help', { type: 'checkbox' })}
        />

        <NumberInput label="Expiration period"
                      description="The number of days drafts will be kept before being expired"
                      min={7} max={60}
                      {...form.getInputProps('settings.refunds_expiration_period')}
        />
      </>}
    </SimpleGrid>
  </>;
}
