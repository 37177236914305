import { IResourceComponentsProps } from "@refinedev/core";
import { IUserResponse } from '@interfaces';
import {
  Button,
  Card,
  Group,
  Loader,
  MultiSelect,
  Stack,
  Switch,
  TextInput,
  Title,
  Text,
  Divider, SimpleGrid
} from '@mantine/core';
import { Feature } from 'flagged';
import * as Yup from 'yup';
import { useForm, yupResolver } from '@mantine/form';
import { useIdentity } from '@components/data/Identity.context';
import {
  useAssignedOwnerOptions,
  useOwnerOptions,
  usePermissionOptions,
  useResendUserInvitation,
  useRolesOptions
} from '@components/hooks';
import { ResourceForm } from '@components/ui/form/ResourceForm';
import { UserNotificationSettings } from '@components/ui/UserNotificationSettings';
import profileSchema from '../../profile/form/profile-validation-schema';
import { PhoneInput } from '@components/ui/PhoneInput/PhoneInput';

export function UserEdit({ initialData: record }: IResourceComponentsProps<IUserResponse>) {
  const { identity } = useIdentity();

  const { data: permissions } = usePermissionOptions();
  const { data: roles, isLoading: isRolesLoading } = useRolesOptions();
  const { data: owners, isLoading: isOwnersLoading } = useOwnerOptions();
  const { data: assignedOwners, isLoading: isAssignedOwnersLoading } = useAssignedOwnerOptions();

  const { sendInvitation, isLoading: isResending, isSuccess } = useResendUserInvitation();

  const adminRoleId = roles?.data.find(role => role.label === 'Admin')?.value;
  const userRoleId = roles?.data.find(role => role.label === 'User')?.value;
  const refundPermissionId = String(permissions?.data.find(role => role.label === 'refund.disbursement')?.value) || null;

  const schema = Yup.object().shape({
    roles: Yup.array().min(1, 'Select at least one role'),
    permissions: Yup.array(),
    owners: Yup.array().when('roles', {
      is: (value) => !value.includes(adminRoleId) && identity.roles.includes('Admin'),
      then: (schema) => schema.min(1, 'Assign at least one owner'),
    }),
    ...profileSchema(identity.owner?.country_code).fields,
  });

  const form = useForm({
    validate: yupResolver(schema),
    initialValues: {
      name: record?.data.name ?? '',
      email: record?.data.email ?? '',
      phone_number: record?.data.phone_number ?? '',
      roles: record?.data.roles?.map(v => `${v.id}`) ?? [],
      permissions: record?.data.permissions?.map(v => `${v.id}`) ?? [],
      owners: record?.data.owners?.map(String) ?? [],
      settings: {
        'notification_channels': record?.data.settings['notification_channels'] ?? ['email'],
        'notification_help': record?.data.settings['notification_help'] ?? true,
        'notification_help_closed': record?.data.settings['notification_help_closed'] ?? false,
        'notification_refund': record?.data.settings['notification_refund'] ?? true,
        'notification_refund_types': record?.data.settings['notification_refund_types'] ?? ['created', 'canceled', 'unclaimed'],
        'notification_feedback': record?.data.settings['notification_feedback'] ?? true,
        'notification_voice-message': record?.data.settings['notification_voice-message'] ?? true,
      }
    },
  });

  return <ResourceForm form={form}>
    <Group justify="space-between" mb="lg">
      <Title order={2}>User</Title>

      { record?.data && !record?.data.email_verified_at &&
        <Button loading={isResending}
                disabled={isSuccess}
                onClick={() => sendInvitation(record.data.id)}>
          Resend Invitation
        </Button>
      }
    </Group>

    <Card shadow="sm" radius="sm">
      <Card.Section withBorder p="md">
        <Stack>
          <SimpleGrid cols={{ md: 2 }} spacing="md">
            <TextInput label="Name" { ...form.getInputProps('name') } />

            <TextInput label="Email" { ...form.getInputProps('email') } />

            <PhoneInput label="Phone Number" { ...form.getInputProps('phone_number') } />

            <MultiSelect label="Role"
                         placeholder="Select a role..."
                         searchable
                         hidePickedOptions
                         disabled={identity.id === record?.data.id}
                         leftSection={isRolesLoading && <Loader size="xs" />}
                         data={roles?.data || []}
                         {...form.getInputProps('roles')}
            />
          </SimpleGrid>

          <Feature name="admin">
            {form.values.roles.length > 0 && !form.values.roles.includes(adminRoleId) &&
              <MultiSelect
                label="Assigned owner(s)"
                placeholder="Select an owner..."
                searchable
                leftSection={isOwnersLoading && <Loader size="xs"/>}
                data={owners?.data || []}
                {...form.getInputProps('owners')}
              />
            }
          </Feature>

          <Feature name="owner">
            {form.values.roles.length > 0 && !form.values.roles.includes(adminRoleId) && assignedOwners?.data.length > 1 &&
              <MultiSelect
                label="Assigned account(s)"
                placeholder="Select an account..."
                searchable
                hidePickedOptions
                disabled={identity.id === record?.data.id}
                leftSection={isAssignedOwnersLoading && <Loader size="xs"/>}
                data={assignedOwners?.data || []}
                {...form.getInputProps('owners')}
              />
            }
          </Feature>

          {
            // TODO Clean this up...
          }
          {refundPermissionId && form.values.roles.length === 1 && form.values.roles.includes(userRoleId) && <>
            <Text size="sm" fw={500}>Permissions</Text>

            <Switch label="Allow managing of refunds"
                    description="This will allow the approval and declining of refunds."
                    onLabel="Yes" offLabel="No"
                    value={refundPermissionId}
                    checked={form.values.permissions.includes(refundPermissionId)}
                    onChange={(event) => {
                      let values: string[] = form.values.permissions;

                      values = values.filter(v => v !== refundPermissionId);
                      if (event.target.checked) {
                        values.push(refundPermissionId);
                      }

                      form.setFieldValue('permissions', values);
                    }} />
          </>}

          <Divider />

          <UserNotificationSettings />
        </Stack>
      </Card.Section>

      <Card.Section px="md" py="sm">
        <Group justify="right">
          <ResourceForm.CancelButton />
          <ResourceForm.SubmitButton />
        </Group>
      </Card.Section>
    </Card>
  </ResourceForm>
}
