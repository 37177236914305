import { CollapsableCard } from '@components/ui';
import { Switch, Input, Divider, Stack, SimpleGrid, Select, Loader } from '@mantine/core';
import { useForm, yupResolver } from '@mantine/form';
import { useInvalidate, useResource, useUpdate } from '@refinedev/core';
import { useDebouncedCallback, useDidUpdate, useId } from '@mantine/hooks';
import { IconCheck } from '@tabler/icons-react';
import { Feature } from 'flagged';
import { ICustomerRecord, IOwnerRecord } from '@interfaces';

import switchClasses from '@components/styles/components/Switch.module.css';
import { EditableInput } from '@components/ui/form/EditableInput';
import * as Yup from 'yup';
import { EMAIL_REGEX } from '@components/ui/EmailRegex';
import { useIdentity } from '@components/data/Identity.context';
import { setFormErrorsFromResponse } from '@components/ui/form/ResourceForm';
import { useTableExpandedRowResource } from '@components/table/TableExpandedRowResource.context';
import { VisitorIdBadge } from '@components/data/cards/CustomerCard/VisitorIdBadge';

type Props = {
  customer: ICustomerRecord;
  onUpdated?: (data: ICustomerRecord) => void;
}

export const customerSchema = (owner: IOwnerRecord) => Yup.object().shape({
  name: Yup.string().required('Enter a name'),
  email: Yup.string().nullable().when({
    is: (v) => v,
    then: (schema) => schema.matches(EMAIL_REGEX, 'Enter a valid email'),
  })
    .when(['phone_number'], {
      is: (v) => !v,
      then: (schema) => schema.required('Enter a mobile or email'),
    }),
  phone_number: Yup.string().nullable().when({
    is: (v) => v,
    then: (schema) => schema.phone(owner?.country_code as any || 'US', 'Enter a valid mobile').required('Enter a mobile'),
  })
    .when(['email'], {
      is: (v) => !v,
      then: (schema) => schema.required('Enter a mobile or email'),
    }),
  locale: Yup.string().required('Select a language'),
}, [
  ['email', 'phone_number']
]);

export const CustomerCard = ({ customer, onUpdated }: Props) => {
  const { owner } = useIdentity();
  const { mutate: update, isLoading } = useUpdate<ICustomerRecord>();
  const { resource } = useResource();
  const tableResource = useTableExpandedRowResource();
  const invalidate = useInvalidate();

  // Using a random ID with the field ID's due to this card possibly appearing multiple times.
  const fieldId = useId();

  const initialValues = (data = customer) => data;

  const form = useForm({
    validate: yupResolver(customerSchema(owner)),
    initialValues,
  });

  useDidUpdate(() => {
    form.setInitialValues(initialValues());
    form.reset();
  }, [customer]);

  const handleSave = useDebouncedCallback(() => {
    update({
      resource: 'customer',
      id: customer.id,
      values: form.values,
      successNotification: { type: 'success', message: 'Successfully updated customer details' },
    }, {
      onSuccess: (data) => {
        !!tableResource && invalidate({ resource: resource.name, invalidates: ['all'] });
        onUpdated && onUpdated(data.data);
      },
      onError: ({ response }) => {
        setFormErrorsFromResponse(response, form);
      },
    });
  }, 500);

  useDidUpdate(() => {
    if (!form.validate().hasErrors && form.values !== customer) {
      handleSave();
    }
  }, [form.values])

  return <CollapsableCard label="Customer"
                          collapsable={false}
                          leftSection={isLoading && <Loader size="xs" />}
                          rightSection={<VisitorIdBadge customer={customer} />}
  >
    <SimpleGrid cols={{ md: 2 }} spacing="xs">
      <EditableInput label="Name" form={form} field="name" />
      <EditableInput label="Email" form={form} field="email" />
      <EditableInput label="Mobile" form={form} field="phone_number"
                     leftSection={<IconCheck strokeWidth={1.5} />}
      />

      <Select label="Language"
              data={[
                { value: 'en', label: 'English' },
                { value: 'es', label: 'Español' },
                { value: 'fr', label: 'French' },
              ]}
              {...form.getInputProps('locale')}
      />
    </SimpleGrid>

    <Divider my="md" />

    <Stack gap={5} mt="xs">
      <Input.Wrapper id={`exclude_feedback_${fieldId}`} label="Exclude from feedback automation" className={switchClasses.inline}>
        <Switch id={`exclude_feedback_${fieldId}`}
                offLabel="No" onLabel="Yes" size="md"
                {...form.getInputProps('settings.feedback_exclude_automation', { type: 'checkbox' })}
        />
      </Input.Wrapper>

      <Feature name="refunds.has_automation">
        <Input.Wrapper id={`exclude_refunds_${fieldId}`} label="Exclude from refund automation" className={switchClasses.inline}>
          <Switch id={`exclude_refunds_${fieldId}`}
                  offLabel="No" onLabel="Yes" size="md"
                  {...form.getInputProps('settings.refunds_exclude_automation', { type: 'checkbox' })}
          />
        </Input.Wrapper>
      </Feature>

      <Input.Wrapper id={`unsubscribed_${fieldId}`} label="Customer is unsubscribed" className={switchClasses.inline}>
        <Switch id={`unsubscribed_${fieldId}`}
                offLabel="No" onLabel="Yes" size="md"
                {...form.getInputProps('settings.notifications_unsubscribed', { type: 'checkbox' })}
        />
      </Input.Wrapper>

      <Input.Wrapper id={`marked_status_${fieldId}`} label="Mark customer as" className={switchClasses.inline}>
        <Select
          size="xs" w={100}
          data={[
            { value: 'None', label: 'None' },
            { value: 'Flagged', label: 'Flagged' },
            { value: 'Blocked', label: 'Blocked' },
          ]}
          {...form.getInputProps('marked_status')}
        />
      </Input.Wrapper>
    </Stack>
  </CollapsableCard>;
}
