import { ContextDrawerProps, drawers } from '@components/ui/drawerManager';
import { RequestStateProvider } from '@components/ui/drawers/Requests/RequestState.context';
import { RequestLayout } from '@components/ui/drawers/Requests/RequestLayout';
import { IHelpRecord, IRefundRecord, IRequestLinkRecord, IRequestRecord, IVoiceMessageRecord } from '@interfaces';

type Props = {
  type: 'help' | 'refund' | 'link'
  voiceMessage?: IVoiceMessageRecord;
  help?: IHelpRecord;
  refund?: IRefundRecord;
  request?: IRequestRecord;
  requestLink?: IRequestLinkRecord;
}

export const openRequestDrawer = ({ type, voiceMessage, help, refund, request, requestLink }: Props) => drawers.openContextDrawer({
  drawer: 'request',
  size: '1000px',
  styles: {
    content: { paddingBottom: 80 }
  },
  innerProps: {
    type,
    voiceMessage,
    help,
    refund,
    request,
    requestLink,
  },
  zIndex: 150,
  closeOnEscape: false,
  withCloseButton: false,
});

export const RequestDrawer = ({ innerProps }: ContextDrawerProps<Props>) => {
  return <>
    <RequestStateProvider type={innerProps.type}
                          voiceMessage={innerProps.voiceMessage}
                          help={innerProps.help}
                          refund={innerProps.refund}
                          request={innerProps.request}
                          requestLink={innerProps.requestLink}>
      <RequestLayout />
    </RequestStateProvider>
  </>;
};
