import { Text } from '@mantine/core';
import { CollapsableCard } from '@components/ui';
import { Notes } from '@components/ui/notes/Notes';
import { INoteRecord } from '@interfaces';

interface Props {
  notes: INoteRecord[];
  type: 'request' | 'customer';
  id: number;
}

export const NotesCard = ({ notes, type, id }: Props) => {
  const label = <>
    Notes
    <Text component="span" size="xs" fw="lighter" c="dimmed" ml="xs">(Internal use only, not seen by customers)</Text>
  </>

  return <CollapsableCard variant="light" label={label} collapsable={false}>
    <Notes notes={notes} type={type} id={id} />
  </CollapsableCard>;
}
