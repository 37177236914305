export default [
  {
    key: 'landing_step',
    title: 'Landing Step',
    fields: [
      {
        name: 'steps.selector.with_tag_title',
        title: 'Landing Page Title - With Tag',
        description: 'This is displayed when an equipment tag is attached.'
      },
      {
        name: 'steps.selector.without_tag_title',
        title: 'Landing Page Title - No Tag',
        description: 'This is displayed when no equipment tag is attached.'
      },
      {
        name: 'fields.machine_id.label',
        title: 'Landing Page Field Label',
      },
      {
        name: 'fields.machine_id.text',
        title: 'Landing Page Field Subtext',
      },
    ],
  },
  {
    key: 'success_step',
    title: 'Confimation Step',
    fields: [
      {
        name: 'steps.success.title',
        title: 'Confirmation Title',
        tokens: [':name']
      },
      {
        name: 'steps.success.text',
        title: 'Confirmation subtext',
      },
    ],
  },
  {
    key: 'step_titles',
    title: 'Step Titles & Descriptions',
    fields: [
      {
        name: 'fields.feedback.label',
        title: 'Feedback Description Title',
      },
      {
        name: 'steps.rate.title',
        title: 'Feedback Rating Title',
      },
      {
        name: 'fields.refund_requested.label',
        title: 'Refund Request Question Title',
      },
      {
        name: 'fields.refund_requested.text',
        title: 'Refund Request Question Subtext',
      },
      {
        name: 'fields.request_type.label',
        title: 'Request Type Title',
      },
      {
        name: 'fields.machine_type.label',
        title: 'Equipment Type Title',
      },
      {
        name: 'fields.description.label',
        title: 'Description Title',
      },
      {
        name: 'fields.description.text',
        title: 'Description Subtext',
      },
      {
        name: 'fields.photo.label',
        title: 'Photo Title',
      },
      {
        name: 'fields.refund_payment_method.text',
        title: 'Payment Method Subtext (Optional)',
      },
      {
        name: 'fields.refund_method.text',
        title: 'Refund Method Subtext (Optional)',
      },
      {
        name: 'steps.contact.title',
        title: 'Contact Details Title',
      },
    ],
  },
  {
    key: 'step_fields',
    title: 'Field Text & Messages',
    fields: [
      {
        name: 'fields.description.validations.required',
        title: 'Description Validation Message',
      },
      {
        name: 'fields.machine_id.aside.required_text',
        title: 'Equipment ID Required Text',
        description: 'This is displayed when an equipment ID is required'
      },
      {
        name: 'fields.machine_id.aside.optional_text',
        title: 'Equipment ID Optional Text',
        description: 'This is displayed when an equipment ID is optional'
      },
      {
        name: 'fields.machine_location.label',
        title: 'Equipment Location Title',
        description: 'This is the title for the field displayed in the contact details when Location option is turned on'
      },
      {
        name: 'fields.refund_address.aside',
        title: 'Postal Address Subtext (Optional)',
        description: 'This is displayed as subtext below the postal address look up field'
      },
    ],
  },
  {
    key: 'pre_auth_step',
    title: 'Pre-Auth FAQs',
    fields: [
      {
        name: 'steps.auth_hold_info.title',
        title: 'Pre-Auth Title',
      },
      {
        name: 'steps.auth_hold_info.text',
        title: 'Pre-Auth Body',
      },
      {
        name: 'steps.auth_hold_info.faqs.title_1',
        title: 'Pre-Auth First Dropdown Title',
      },
      {
        name: 'steps.auth_hold_info.faqs.text_1',
        title: 'Pre-Auth First Dropdown Body',
      },
      {
        name: 'steps.auth_hold_info.faqs.title_2',
        title: 'Pre-Auth Second Dropdown Title',
      },
      {
        name: 'steps.auth_hold_info.faqs.text_2',
        title: 'Pre-Auth Second Dropdown Body',
      },
    ],
  },
  {
    key: 'general',
    title: 'General',
    fields: [
      {
        name: 'common.words.item',
        title: 'Equipment Identifier',
      },
    ],
  },
];
