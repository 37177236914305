import { Avatar, Box, Group, Indicator, Text } from '@mantine/core';
import { IconCheck, IconFlag } from '@tabler/icons-react';
import { CustomerMarkedStatuses } from '@interfaces';

interface Props {
  customer: {
    name: string;
    marked_status?: CustomerMarkedStatuses;
    confirmed_at?: string;
  };
  compact?: boolean;
}

const COLOR_MAP = {
  'blue': ['a', 'k', 'u'],
  'red': ['b', 'l', 'v'],
  'indigo': ['c', 'm', 'w'],
  'purple': ['d', 'n', 'x'],
  'pink': ['e', 'o', 'y'],
  'orange': ['f', 'p', 'z'],
  'yellow': ['g', 'q'],
  'green': ['h', 'r'],
  'teal': ['i', 's'],
  'cyan': ['j', 't'],
};

export const CustomerAvatar = ({ customer, compact = false }: Props) => {
  let color = '#467FD0';

  const name = customer?.name ?? '';
  const firstLetter = name?.toLowerCase().substring(0, 1) ?? '';

  for (const [hex, letters] of Object.entries(COLOR_MAP)) {
    if (letters.includes(firstLetter)) {
      color = hex;
      break;
    }
  }

  let icon = null;

  if (['Flagged', 'Blocked'].includes(customer.marked_status)) {
    icon = <Indicator color={ customer.marked_status === 'Flagged' ? 'orange' : 'red' }
                      label={<IconFlag strokeWidth={3} size={10}/>}
                      size="lg"
                      pos="absolute" top={3} right={3}
                      styles={{ indicator: { padding: 2 } }}
                      title="Flagged"
    />
  } else if (customer.confirmed_at) {
    icon = <Indicator color={color}
                      label={<IconCheck strokeWidth={4} size={10} />}
                      size="lg"
                      pos="absolute" top={3} right={3}
                      styles={{ indicator: { padding: 2 } }}
                      title="Verified"
    />;
  }

  return <Group gap={4} wrap="nowrap" w={!compact ? '100%' : null}>
    <Box pos="relative">
      { icon }
      <Avatar color={color} radius="xl" size={35}>{firstLetter.toUpperCase()}</Avatar>
    </Box>
    { !compact && <Text truncate="end" flex={1} ml={5}>{ name }</Text> }
  </Group>;
}
