import { IResourceComponentsProps } from "@refinedev/core";
import { IVoiceMessageListResponse } from '@interfaces';
import { VoiceMessageTable } from './table/VoiceMessageTable';
import { useFeatures } from 'flagged';
import { FilterBar } from '@components/filters/FilterBar';
import { FilterSelect } from '@components/filters/FilterSelect';
import { TableFilterProvider } from '@components/table/TableFilter.context';
import { Box, Divider, Flex, Space, useMantineTheme } from '@mantine/core';
import { FilterButtonGroup } from '@components/filters/FilterButtonGroup';
import { FilterToggle } from '@components/filters/FilterToggle';
import { VoiceMessageSettingsForm } from './form/VoiceMessageSettingsForm';
import { CallOut } from "@components/ui/CallOut";
import { useMediaQuery } from '@mantine/hooks';

export function VoiceMessageList({ initialData }: IResourceComponentsProps<IVoiceMessageListResponse>) {
  const theme = useMantineTheme();
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`, false);
  const features = useFeatures();


  const quickFilters = <Flex direction={ isMobile ? 'column' : 'row'} gap="sm" style={{ flex: 1 }}>
    <FilterSelect name="status" label="Status" options={[
      { label: 'All', value: 'All' },
      { label: 'Unread', value: 'Unread' },
      { label: 'Read', value: 'Read' },
      { label: 'Blocked', value: 'Blocked' },
    ]} />
    <Divider orientation={ isMobile ? 'horizontal' : 'vertical' } style={{ height: isMobile ? 0 : 40 }} />
    <Box><FilterToggle name="is_archived" label="Archived" /></Box>
  </Flex>;

  const filters = features.admin ? <FilterSelect name="owners" label="Owner" route="owners" /> : null;

  return <TableFilterProvider>
    <FilterBar filters={filters} quickFilters={quickFilters} />

    { !features.admin && <VoiceMessageSettingsForm /> }

    <CallOut title="NOTE: messages are automatically transcribed using voice recognition technology. Content and references may contain mistakes and errors." />

    <Space h={16} visibleFrom="sm" />

    <VoiceMessageTable initialData={initialData} syncWithLocation={true} />
  </TableFilterProvider>;
}
