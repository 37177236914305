import { IRefundRecord } from '@interfaces';
import { NumberInput } from '@mantine/core';
import { IconCurrencyDollar } from '@tabler/icons-react';
import { useRefundRequestFormContext } from './RefundRequestForm.context';
import { useInvalidate, useUpdate } from '@refinedev/core';
import { useFeatures } from 'flagged';
import { EditableInput } from '@components/ui/form/EditableInput';
import { useDidUpdate } from '@mantine/hooks';

interface Props {
  refund: IRefundRecord;
}

export const RefundAmountInput = ({ refund }: Props) => {
  const form = useRefundRequestFormContext();
  const { mutate: update, isLoading } = useUpdate();
  const invalidate = useInvalidate();
  const features = useFeatures();

  useDidUpdate(() => {
    if (form.errors.amount || form.values.amount === Number(refund.amount)) {
      return;
    }

    update({
      resource: 'refund',
      id: refund.id,
      values: {
        amount: Number(form.values.amount).toFixed(2)
      }
    }, {
      onSuccess: () => {
        invalidate({ resource: 'refund', invalidates: ['detail', 'list'] });
      }
    });
  }, [form.values.amount]);

  useDidUpdate(() => {
    form.setFieldValue('amount', Number(refund.amount));
  }, [refund.amount]);

  return <>
    <EditableInput form={form} field="amount"
                   label="Amount"
                   description={Number(refund.requested_amount) !== form.values.amount ? `Original requested amount $${refund.requested_amount}` : ''}
                   isEditable={!!features['refund.update'] && refund.meta.is_editable && !['Draft', 'Expired'].includes(refund.status)}
                   isLoading={isLoading}
                   renderRoot={(props) => (
                     <NumberInput decimalScale={2}
                                  fixedDecimalScale
                                  step={.05}
                                  hideControls
                                  styles={{ input: { padding: 0, border: 0, minHeight: 0, height: 'calc(var(--input-height) - 2px)' } }}
                                  {...props} />
                   )}
                   leftSection={<IconCurrencyDollar />}
    />
  </>;
}
